@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    /* map */
    .listing-map-wrapper,
    .wanted-map-wrapper,
    .project-map-wrapper {
        @apply w-full lg:w-3/5 relative hidden md:block flex-grow h-full;
    }
    .listing-map-wrapper.show,
    .wanted-map-wrapper.show,
    .listing-card-wrapper.show,
    .wanted-card-wrapper.show,
    .project-map-wrapper.show,
    .project-card-wrapper.show {
        @apply block;
    }

    .wanted-card-wrapper,
    .listing-card-wrapper,
    .project-card-wrapper {
        @apply relative h-full w-full lg:w-2/5 overflow-y-auto bg-white hidden md:block;
    }

    .label-map {
        @apply py-1 pr-1 font-normal text-xs text-white transform rounded-sm;
    }
    .label-map.visit {
        @apply scale-105;
    }

    .label-map.visit-lg {
        @apply scale-105 border border-black;
    }

    .label-map-project.visit .marker-wrapper-project {
        @apply scale-110;
    }

    .label-map-project.visit-lg .marker-wrapper-project {
        opacity: 1 !important;
        z-index: 10000001 !important;
        @apply scale-125;
    }

    .label-map.label-map-green,
    .marker-wrapper-project .label-text.label-map-green {
        @apply bg-green-light;
    }
    .label-map.label-map-red,
    .marker-wrapper-project .label-text.label-map-red {
        @apply bg-red;
    }
    .label-map.label-map-orange,
    .marker-wrapper-project .label-text.label-map-orange {
        @apply bg-orange;
    }

    .info-img-prop-wrapper {
        @apply border border-gray-100 relative w-28 h-28;
    }

    .loading-map-section {
        z-index: 99999999;
        @apply absolute inset-0 bg-white bg-opacity-90 hidden items-center justify-center;
    }

    .loading-map-section.show {
        @apply hidden;
    }

    /* navigation */
    .navigation {
        @apply fixed top-0 inset-x-0 z-20 transition-all ease-linear duration-300 transform py-4 font-display;
    }
    .navigation.nav-home {
        @apply bg-white;
    }
    .navigation.nav-home.scroll {
        @apply bg-opacity-95 shadow-xl;
    }

    .navigation.nav-home .nav-wrapper {
        @apply container mx-auto flex justify-between items-center relative;
    }
    .navigation.nav-home .nav-wrapper .menu-items {
        @apply hidden md:flex space-x-4 items-center;
    }
    .navigation.nav-home .nav-wrapper .menu-items li.menu-item {
        @apply text-black text-base;
    }
    .navigation.nav-home.scroll .nav-wrapper .menu-items li.menu-item {
        @apply text-lg;
    }

    .navigation.nav-home .nav-wrapper .menu-items-mobile {
        @apply md:hidden bg-green  text-white px-7 pt-4 pb-4 space-y-3 absolute right-0 w-8/12 top-full z-20 shadow-lg mt-4;
    }
    /* .navigation.nav-home .nav-wrapper .menu-items-mobile  */
    /* .nav-toggler{
        @apply absolute top-4 right-4;
    } */

    .navigation.nav-home .nav-wrapper .menu-items-mobile.show {
        @apply visible;
    }

    .navigation.nav-home .nav-wrapper .menu-items-mobile li.menu-item {
        @apply text-base font-normal;
    }

    .menu-items-2 {
        @apply absolute md:static hidden right-0 top-full left-auto w-8/12 md:w-auto bg-green md:bg-transparent md:flex flex-col md:flex-row lg:flex space-x-0 space-y-2 md:space-y-0 md:space-x-4 items-start md:items-center text-white md:text-black px-7 py-4 md:py-0 md:px-0;
    }
    .menu-items-2.show {
        @apply flex;
    }

    /* .navigation  */
    .nav-toggler {
        z-index: 21;
        @apply w-7 h-7 relative;
    }
    .nav-toggler span {
        @apply w-full h-1 absolute left-0 bg-white -translate-x-1/2 duration-300;
    }
    .nav-toggler span:nth-of-type(1) {
        top: 20%;
    }
    .nav-toggler span:nth-of-type(2) {
        top: 50%;
    }
    .nav-toggler span:nth-of-type(3) {
        top: 80%;
    }
    .nav-toggler.show span:nth-of-type(1) {
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
    }
    .nav-toggler.show span:nth-of-type(2) {
        top: 50%;
        transform: translateY(-50%) rotate(-45deg);
    }
    .nav-toggler.show span:nth-of-type(3) {
        left: -100%;
        opacity: 0;
    }

    /* .nav-toggler .close, .nav-toggler.show .default{
        @apply hidden;
    } */

    /* .nav-toggler.show .close, .nav-toggler .default{
        @apply block;
    } */

    .nav-toggler-2.show,
    .nav-toggler.show {
        @apply text-white;
    }
    .nav-toggler-2 {
        z-index: 21;
        @apply absolute right-0 top-0 ml-auto text-green block sm:hidden p-1 mr-2 mt-1;
    }

    .nav-toggler-2 .close,
    .nav-toggler-2.show .default {
        @apply hidden;
    }

    .nav-toggler-2.show .close,
    .nav-toggler-2 .default {
        @apply block;
    }

    /* logo */
    .logo-wrapper {
        @apply w-115px md:w-auto block;
    }

    /* banner */
    .hero {
        @apply h-450px md:h-485px xl:h-600px relative flex items-center;
    }
    .hero .swiper-container {
        @apply absolute inset-0;
    }
    .hero .swiper-container .swiper-slide .home-banner {
        @apply w-full h-450px md:h-485px xl:h-600px relative bg-cover bg-top bg-no-repeat filter brightness-50;
    }
    .hero .swiper-pagination-bullet {
        @apply w-8 h-1 rounded-none bg-white bg-opacity-95;
    }
    .hero .swiper-pagination-bullet.swiper-pagination-bullet-active {
        @apply bg-green;
    }

    /* tabs */
    .search-tab-wrapper {
        @apply max-w-3xl mx-auto md:text-lg text-sm;
    }
    .search-tab-wrapper .input-search {
        @apply block w-5/6 md:w-11/12 text-left text-gray-500 py-2 pl-2 md:py-4 md:pl-6 border-0 appearance-none cursor-pointer text-xs md:text-base;
    }
    .search-tab-wrapper .tab-menu {
        @apply flex flex-row flex-wrap;
    }
    .search-tab-wrapper .tab-menu .menu-item {
        @apply font-display font-semibold md:font-bold px-2 py-1 md:px-4 md:py-2 bg-white bg-opacity-80 cursor-pointer border-t-0 hover:border-t-2 border-green-light;
    }
    .search-tab-wrapper .tab-menu .menu-item.active {
        @apply border-t-2 border-green-light bg-opacity-90;
    }

    /* section title */
    .section-title {
        @apply font-bold mb-2 text-xl lg:text-2xl font-display;
    }

    .section-title.title-green {
        @apply text-green;
    }

    /* card popular */
    .image-wrap {
        @apply bg-center bg-cover bg-no-repeat w-full h-230px bg-gray-50 bg-opacity-40 relative;
    }

    .project-description {
        @apply text-green-light text-xs font-medium mb-2 truncate;
    }
    .project-description p {
        @apply truncate;
    }

    /* listing page component here */
    .slider-property .swiper-pagination-bullets .swiper-pagination-bullet {
        @apply w-5 h-5 bg-transparent border-2 shadow border-white transition-all ease-linear duration-300;
    }
    .slider-property
        .swiper-pagination-bullets
        .swiper-pagination-bullet.swiper-pagination-bullet-active {
        @apply bg-white;
    }
    .thumb-slider-property {
        @apply h-20 transition-all ease-linear duration-300;
    }
    .thumb-slider-property.active {
        @apply border-2 border-green;
    }
    .thumb-slider-property.swiper-slide-thumb-active {
        @apply border-2 border-green;
    }

    /* projects page component here */
    .excelence-container {
        @apply tracking-tight text-base;
    }

    .excelence-container ul {
        @apply list-disc list-inside;
    }

    .excelence-container ol {
        @apply list-decimal list-inside;
    }

    .unit-property-size {
        @apply text-xs font-thin mb-2;
    }

    .unit-property-size ul,
    .unit-property-size ol {
        @apply flex flex-row m-0 p-0 space-x-1;
    }

    /* slider adds component */
    .slider-adds {
        @apply pb-9;
    }
    .slider-adds .swiper-pagination-bullets .swiper-pagination-bullet {
        @apply w-3 h-3 bg-transparent shadow bg-gray-400 hover:bg-gray-700 transition-all ease-linear duration-300;
    }
    .slider-adds
        .swiper-pagination-bullets
        .swiper-pagination-bullet.swiper-pagination-bullet-active {
        @apply bg-gray-700;
    }
    .slider-adds img {
        @apply cursor-pointer;
    }

    /* button */
    .button-filter {
        @apply px-1 py-1 md:py-2 md:px-4 text-xs text-black bg-gray-100 shadow transition-colors duration-300 ease-linear cursor-pointer min-w-0 lg:min-w-115px text-center flex justify-center items-center 2xl:w-full;
    }

    .cb-modal-search .button-filter {
        @apply py-2 px-4;
    }

    .type-box .button-filter {
        @apply w-auto md:w-auto;
    }

    .category-button .button-filter {
        @apply md:flex-grow-0 flex-grow;
    }

    .button-filter.green.active {
        @apply bg-green text-white;
    }
    .button-filter.green-light.active {
        @apply bg-green-light text-white;
    }
    .button-filter.red.active {
        @apply bg-red text-white;
    }
    .button-filter.orange.active {
        @apply bg-orange text-white;
    }

    .button-sort {
        z-index: 1;
        @apply text-xxs py-2 px-4 inline-block border-green border;
    }

    .button-filter-adv {
        z-index: 1;
        @apply text-xxs py-2 px-4 hidden md:inline-block border-green border;
    }

    /* sorting component */
    .sort-box {
        @apply max-w-200px w-max absolute left-0 md:left-auto md:right-0 top-full shadow border border-green bg-white text-sm mt-4 transform -translate-y-4 invisible opacity-0 transition-all duration-300;
    }

    .sort-box.show {
        @apply -translate-y-0 visible opacity-100;
    }

    .sort-box .sort-direction-box {
        @apply flex flex-row flex-nowrap cursor-pointer border-b border-green divide-x divide-green;
    }
    .sort-box .sort-direction-box .sort-direction-item {
        @apply px-2 py-1 transition-colors duration-300;
    }
    .sort-box .sort-direction-box .sort-direction-item.active {
        @apply bg-gray-100 bg-opacity-95;
    }

    .sort-box .sort-selection .sort-selection-item {
        @apply hover:bg-gray-100 transition-colors py-1 px-2 cursor-pointer;
    }
    .sort-box .sort-selection .sort-selection-item.active {
        @apply bg-gray-100;
    }

    /* card-wrapper & filter-box */
    .card-wrapper {
        @apply w-full grid grid-cols-1 md:grid-cols-2 gap-4;
    }

    .pagination-wrapper {
        @apply py-2 sticky bottom-0 bg-white w-full;
    }

    .pagination-wrapper .paginator {
        @apply mx-auto flex flex-row flex-nowrap justify-center items-center;
    }

    .pagination-wrapper .paginator a:first-child {
        @apply mr-px md:mr-1;
    }
    .pagination-wrapper .paginator a:last-child {
        @apply ml-px md:ml-1;
    }
    .pagination-wrapper .paginator a {
        @apply flex w-8 h-8 md:w-10 md:h-10 mx-px md:mx-1 justify-center items-center rounded-full border border-gray-400 bg-white text-black hover:border-green;
    }

    .pagination-wrapper .paginator a.paginator-current {
        @apply text-green border-green font-semibold;
    }

    .card-wrapper.wanted {
        @apply w-full grid grid-cols-1 md:grid-cols-2 gap-2;
    }
    .card-wrapper.hide {
        @apply hidden;
    }

    .filter-wrapper {
        @apply sticky top-0 bg-white p-2 z-10 grid grid-cols-1 gap-y-2 shadow;
    }
    .filter-wrapper .category-button {
        @apply hidden md:flex flex-row space-x-2 justify-between xl:justify-around w-full;
    }

    .filters-box {
        @apply hidden w-full lg:w-2/5 fixed z-20 bg-white bottom-0 right-0 top-auto md:top-36 overflow-y-auto px-4 md:px-2 md:pb-12 md:pt-0 pt-4 pb-4 border-t border-gray-500 md:border-t-0;
    }

    .filters-box.show {
        @apply block;
    }

    .filters-box .close {
        z-index: 1;
        @apply absolute w-6 h-6 shadow-lg top-2 right-4 text-white cursor-pointer hover:scale-110 scale-100 transform duration-300 transition-all p-1 bg-green rounded-full;
    }
    .filters-box .close svg {
        @apply w-full h-full;
    }
    .filters-box .title {
        @apply relative mb-2 text-base font-semibold text-green;
    }
    .filters-box .subtitle {
        @apply mb-2 text-base font-medium;
    }
    .filter-items-wrapper {
        @apply w-full flex flex-col divide-y-2 divide-gray-200 items-start relative;
    }

    .filter-items-wrapper .filter-items {
        @apply pb-4 pt-2 w-full;
    }

    .filter-items-wrapper .filter-items.submit-filter-wrapper {
        @apply pb-4 pt-2 w-full border-0 sticky bottom-4 lg:-bottom-10;
    }

    .submit-filter-wrapper .button-filter {
        @apply text-base mx-auto shadow p-2 font-display font-medium bg-green;
    }

    /* filters component */
    .filter-items .select-form {
        @apply bg-white p-2 border border-green shadow rounded-sm text-xs;
    }

    .residential-type,
    .commercial-type,
    .industrial-type {
        @apply flex space-x-6 flex-row overflow-x-auto;
    }

    .residential-type div.type-res-wrap label,
    .commercial-type div.type-res-wrap label,
    .industrial-type div.type-res-wrap label {
        @apply text-xs font-medium;
    }
    .residential-type div.type-res-wrap,
    .commercial-type div.type-res-wrap,
    .industrial-type div.type-res-wrap {
        max-width: 50px;
        @apply text-center flex flex-col items-center space-y-2;
    }
    .residential-type div.label,
    .commercial-type div.label,
    .industrial-type div.label {
        @apply cursor-pointer w-12 h-12 flex items-center justify-center bg-gray-200 rounded-full transition-colors ease-in-out duration-300 p-px border border-gray-600 text-white;
    }

    .residential-type div.label:hover {
        @apply md:bg-green-light;
    }
    .commercial-type div.label:hover {
        @apply md:bg-red;
    }
    .industrial-type div.label:hover {
        @apply md:bg-orange;
    }

    .residential-type div.label.active {
        @apply bg-green-light;
    }
    .commercial-type div.label.active {
        @apply bg-red;
    }
    .industrial-type div.label.active {
        @apply bg-orange;
    }
    .residential-type div.label svg,
    .commercial-type div.label svg,
    .industrial-type div.label svg {
        @apply w-7 h-7 fill-current;
    }

    /* CARD PROPS */
    .card-props {
        @apply flex flex-col rounded-sm shadow border;
    }

    .card-props .image-wrap {
        @apply bg-center bg-cover bg-no-repeat w-full h-230px bg-gray-50 bg-opacity-40 relative cursor-pointer;
    }

    .card-props .image-wrap-unit {
        @apply bg-center bg-contain bg-no-repeat w-full h-230px bg-gray-600 bg-opacity-40 relative cursor-pointer;
    }

    .card-props p.prop-name-lg {
        @apply text-sm truncate cursor-pointer;
    }

    .card-props p.prop-name.green {
        @apply text-green-light;
    }
    .card-props p.prop-name.orange {
        @apply text-orange;
    }
    .card-props p.prop-name.red {
        @apply text-red;
    }

    .card-props span.label {
        @apply py-1 px-2 text-xs text-white ml-auto overflow-ellipsis overflow-hidden whitespace-nowrap;
    }
    .card-props span.label.prop-type-green {
        @apply bg-green-light;
    }
    .card-props span.label.prop-type-red {
        @apply bg-red;
    }
    .card-props span.label.prop-type-orange {
        @apply bg-orange;
    }

    .card-props-wanted span.label {
        @apply py-1 px-2 text-xs text-white;
    }
    .card-props-wanted span.label.prop-type-green {
        @apply bg-green-light;
    }
    .card-props-wanted span.label.prop-type-red {
        @apply bg-red;
    }
    .card-props-wanted span.label.prop-type-orange {
        @apply bg-orange;
    }

    .card-props .icon-fav {
        @apply absolute top-12 right-2 h-6 w-6 bg-center bg-cover bg-no-repeat hover:scale-110 transform transition-all cursor-pointer;
    }

    .card-props-wanted {
        @apply flex flex-col rounded-sm shadow-lg cursor-pointer border border-transparent transition-all duration-500 p-3 bg-white;
    }

    .card-props-wanted.in {
        @apply border-gray-500;
    }

    .card-props .ribbon {
        right: -30px;
        top: 6px;
        @apply bg-gray-300 absolute whitespace-nowrap transform rotate-45 py-2 px-8 shadow-lg z-10 overflow-hidden;
    }
    .card-props .ribbon.red {
        @apply bg-red;
    }
    .card-props .ribbon.yellow {
        @apply bg-orange;
    }
    .card-props .ribbon.green {
        @apply bg-green;
    }
    .card-props .ribbon span {
        @apply font-semibold text-black text-sm text-center;
    }

    .card-props .ribbon.red span,
    .card-props .ribbon.yellow span,
    .card-props .ribbon.green span {
        @apply text-white;
    }

    .card-props-wanted .ribbon {
        right: -30px;
        top: 6px;
        @apply bg-gray-300 absolute whitespace-nowrap transform rotate-45 py-2 px-8 shadow-lg z-10 overflow-hidden;
    }
    .card-props-wanted .ribbon.red {
        @apply bg-red;
    }
    .card-props-wanted .ribbon.yellow {
        @apply bg-orange;
    }
    .card-props-wanted .ribbon.green {
        @apply bg-green;
    }
    .card-props-wanted .ribbon span {
        @apply font-semibold text-black text-sm text-center;
    }
    .card-props-wanted .ribbon.red span,
    .card-props-wanted .ribbon.yellow span,
    .card-props-wanted .ribbon.green span {
        @apply text-white;
    }

    /* end listing page component here */

    /* property details */
    table.property-d th,
    table.property-d td {
        @apply p-1 align-baseline;
    }

    table.property-d tbody tr:nth-child(even) {
        @apply bg-gray-100;
    }

    /* modal */
    .cb-modal-search {
        @apply fixed inset-0 bg-black bg-opacity-90 hidden justify-center overflow-hidden py-8;
    }

    .cb-modal-search.show {
        @apply flex;
    }

    .cb-modal {
        @apply h-screen w-full fixed left-0 top-0 hidden justify-center items-center bg-black bg-opacity-50 z-50;
    }
    .cb-modal.show {
        @apply flex;
    }

    .cb-modal .cb-modal-template {
        @apply bg-white shadow-lg w-10/12 md:w-500px;
    }
    .cb-modal .cb-modal-template-md {
        @apply bg-white shadow-lg w-10/12 md:w-2/5;
    }
    .cb-modal .cb-modal-template-lg {
        @apply bg-white shadow-lg w-10/12 md:w-3/5;
    }

    .cb-modal .cbm-header {
        @apply px-4 py-2;
    }
    .cb-modal .close-modal {
        @apply float-right;
    }

    .cb-modal .close-modal {
        @apply text-black;
    }
    .cb-modal .cbm-body {
        @apply p-5;
    }

    .cb-modal .cbm-footer {
        @apply w-full border-t p-3;
    }

    /* custom input */
    .float-form-input {
        @apply border-b-2 relative mt-4;
    }
    .float-form-input.green {
        @apply focus-within:border-green;
    }

    .float-form-input input.form {
        @apply block w-full focus:outline-none bg-transparent text-base font-semibold;
    }

    .float-form-input input.form ~ label {
        @apply absolute bottom-0 origin-0 text-gray-700 font-semibold text-base;
    }

    .float-form-input input.form ~ .eyePass {
        @apply absolute bottom-0 origin-0 text-gray-700 font-semibold text-base opacity-0;
    }

    .float-form-input input.form:focus-within ~ .eyePass,
    .float-form-input input.form:not(:placeholder-shown) ~ .eyePass {
        @apply right-0 transform scale-90 -translate-y-1 opacity-100;
    }

    .float-form-input input.form:focus-within ~ label,
    .float-form-input input.form:not(:placeholder-shown) ~ label {
        @apply transform scale-90 -translate-y-5 text-green;
    }

    .float-form-input input.form:focus-within ~ label {
        @apply text-green;
    }

    .form-helper {
        @apply w-4 h-4 bg-center bg-cover bg-no-repeat inline-block align-middle ml-1;
    }

    .form-input-property {
        @apply px-3 py-2 border border-separate text-black border-gray-300 bg-white focus:border-blue-200 focus:outline-none w-full shadow;
    }
    .form-input-property::placeholder {
        @apply text-gray-200;
    }
    .form-input-property.error {
        @apply border-red-fallback;
    }
    .stepper {
        @apply grid grid-cols-4 gap-4 w-full m-auto;
    }
    .stepper.listing-steps {
        @apply grid-cols-4;
    }
    .stepper.wanted-steps {
        @apply grid-cols-3;
    }
    .stepper .item {
        @apply border-t-4 border-gray-200 pt-4 transition-all duration-500;
    }
    .stepper .item.done {
        @apply border-green;
    }
    .stepper .item .title {
        @apply uppercase text-gray-400 font-bold;
    }

    .stepper .item.done .title {
        @apply text-green;
    }
    .listing-form-template-one {
        @apply hidden grid-cols-1 md:grid-cols-2 gap-y-4 gap-x-8 mb-4;
    }
    .listing-form-template-two {
        @apply hidden grid-rows-4 grid-flow-row md:grid-flow-col grid-cols-1 md:grid-cols-2 gap-y-4 gap-x-10 mb-4;
    }
    .listing-form-template-three {
        @apply hidden grid-cols-1 md:grid-cols-5 gap-y-4 gap-x-10 mb-4;
    }
    .listing-form-template-four {
        @apply hidden grid-cols-1 md:grid-cols-5 mb-4 gap-x-10;
    }
    .listing-form-template-one.current,
    .listing-form-template-two.current,
    .listing-form-template-three.current,
    .listing-form-template-four.current {
        @apply grid;
    }
    .wanted-form-template-one {
        @apply hidden grid-cols-1 md:grid-cols-2 gap-y-4 gap-x-10 mb-4;
    }
    .wanted-form-template-two {
        @apply hidden grid-rows-4 grid-flow-row md:grid-flow-col grid-cols-1 md:grid-cols-2 gap-y-4 gap-x-10 mb-4;
    }
    .wanted-form-template-three {
        @apply hidden grid-rows-1 grid-cols-1 mb-4;
    }

    .wanted-form-template-one.current,
    .wanted-form-template-two.current,
    .wanted-form-template-three.current {
        @apply grid;
    }
    .property-form .next-button,
    .property-form .submit-button {
        @apply bg-green-fallback ml-auto px-4 text-white py-1 block;
    }

    .property-form .prev-button {
        @apply bg-gray-600 px-4 text-white py-1 block;
    }

    .property-form .next-button.hide {
        @apply hidden;
    }
    .property-form .prev-button.hide {
        @apply hidden;
    }
    .property-form .submit-button.hide {
        @apply hidden;
    }
    .form-step .multiselect__tags {
        @apply shadow h-auto border rounded-none border-separate text-black border-gray-300 bg-white focus:border-blue-200  focus:outline-none w-full text-base;
    }
    .form-step .multiselect__select {
        @apply top-1;
    }
    .form-step .select-custom.error {
        @apply border-red-fallback;
    }
    .form-step .select-custom .ss-single-selected {
        @apply px-3 py-2 h-auto rounded-none border-0;
    }
    .form-step .select-custom .ss-single-selected span {
        @apply text-base;
    }
    .form-step .select-custom .ss-content .ss-list .ss-option {
        @apply hover:bg-green-fallback py-1 transition-colors ease-in-out;
    }
    .form-step .select-custom .ss-content .ss-list .ss-option.ss-disabled {
        @apply bg-white hover:bg-white;
    }
    .form-step .select-custom .ss-single-selected span.ss-disabled {
        @apply text-gray-400 font-normal;
    }

    .form-step .select-custom .ss-search input {
        @apply rounded-none shadow-none focus:border-0;
    }

    .summary-table {
        @apply grid grid-cols-2 gap-x-4;
    }

    .tables {
        @apply border-collapse border-0 border-green w-full md:w-auto mb-2 md:mb-0;
    }

    .tables tr:nth-child(even) {
        @apply bg-gray-200 text-gray-800;
    }

    .tables th {
        @apply text-left;
        min-width: 150px;
        width: 185px;
    }

    .tables th,
    .tables td {
        @apply border-0 border-green p-1;
    }

    /* footer */
    .footer {
        @apply bg-gray-700 pt-8 pb-4 text-white;
    }

    .footer .content-wrapper {
        @apply grid grid-cols-1 lg:grid-cols-4 w-full gap-x-0 lg:gap-x-4;
    }

    .footer .download-wrapper {
        @apply flex w-full space-x-4;
    }

    .footer p.title {
        @apply mb-2 text-base;
    }

    .footer .column-md {
        @apply px-0 lg:px-4 w-full lg:w-323px;
    }

    .footer .column-sm {
        @apply px-0 lg:px-4 w-full lg:w-210px;
    }

    .alert {
        @apply flex justify-between bg-white rounded overflow-hidden p-2 space-x-1 shadow-sm;
    }

    small.error {
        @apply text-red-fallback;
    }

    .dropzone {
        @apply w-full mb-4 flex flex-col justify-center items-center p-2 bg-gray-300 shadow;
    }

    .dropzone.dragging {
        @apply bg-green-fallback bg-opacity-80;
    }

    .user-navigation {
        @apply flex justify-center items-center space-x-6;
    }

    .user-navigation .item {
        @apply text-lg text-white font-medium relative;
    }

    .user-navigation .item:hover:after,
    .user-navigation .item.active::after {
        content: " ";
        @apply absolute -bottom-2 h-1 w-full block bg-green;
    }

    /* .map-toogler{
        @apply md:hidden inline-block bg-white fixed shadow inset-x-0 mx-auto bottom-4 text-green z-10 p-2 text-base font-semibold max-w-max;
    }

    .map-toogler[data-show="map"]{
        @apply z-10;
    }
    .map-toogler[data-show="list"]{
        z-index: 19;
    } */

    .whatsapp-cta-1 {
        @apply cursor-pointer fixed bg-center bg-cover bg-no-repeat bottom-14 md:bottom-0 right-4 rounded-full shadow-xl z-10 transform w-12 h-12 md:w-16 md:h-16;
    }

    .menu-mobile-bottom {
        @apply h-12 md:hidden w-full bg-green text-xs self-end grid grid-cols-3 text-white font-medium;
    }
    .menu-mobile-bottom .items {
        @apply flex items-center justify-center;
    }
    .ownership-label {
        @apply flex space-x-1 items-center;
    }
    .ownership-label span {
        @apply overflow-ellipsis whitespace-nowrap overflow-hidden;
    }

    /* button */
    .btn {
        @apply px-4 py-1 font-semibold font-display md:font-medium shadow-inner hover:bg-gray-200 text-black bg-gray-50 transition-colors ease-in-out duration-200 text-base;
    }

    .btn.btn-block {
        @apply w-full;
    }

    .btn.btn-primary {
        @apply bg-green bg-opacity-100 hover:bg-green-fallback text-white;
    }

    .btn.btn-light {
        @apply bg-white bg-opacity-100 hover:bg-gray-100 text-green;
    }

    .alert-text {
        @apply mb-4 font-display text-lg text-black font-semibold;
    }

    .alert-text.error {
        @apply text-red-fallback;
    }

    .alert-text.success {
        @apply text-green-fallback;
    }
}
